import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findOne = (platform: string, params: object) => {
  return http.post(SERVICE_API_URL + `/app/${platform}`, params, {
    headers: authHeader()
  });
};

const findWeather = () => {
  return http.post(SERVICE_API_URL + `/app/today`, {
    headers: authHeader()
  });
};

export default {
  findOne,
  findWeather
}