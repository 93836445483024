
import { IonContent, modalController, IonSlides, IonSlide } from "@ionic/vue";
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { VERSION } from "../common/config";
import { getPlatforms } from "@ionic/vue";

export default defineComponent({
  name: "Tutorial",
  components: { IonContent, IonSlides, IonSlide },
  props: {
    popupName: {
      type: String,
      default: null,
      description: "(default -> os)",
    },
    items: {
      type: Array,
      default: null,
      description: "",
    },
    totalItems: {
      type: Number,
      default: 0,
      description: "",
    },
    totalPages: {
      type: Number,
      default: 0,
      description: "",
    },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    console.log(store);
    const state = reactive({
      isPrevBtn: false,
      isNextBtn: true,
      slideOpts: {
        initialSlide: 0,
        slidesPerView: 1,
        speed: 400,
        autoplay: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      popup: {
        items: props.items,
        totalItems: props.totalItems,
        totalPages: props.totalPages,
      },
      popupName: props.popupName,
      version: {
        number: 0,
        code: "",
      },
    });

    const platforms = getPlatforms();
    if (platforms.includes("hybrid") && platforms.includes("ios")) {
      state.version.number = VERSION.ios.number;
      state.version.code = VERSION.ios.code;
    } else if (platforms.includes("hybrid") && platforms.includes("android")) {
      state.version.number = VERSION.android.number;
      state.version.code = VERSION.android.code;
    } else {
      state.version.number = VERSION.web.number;
      state.version.code = VERSION.web.code;
    }

    const refSlides = ref<any>(null);

    const slidePrev = async () => {
      const s = await refSlides?.value?.$el.getSwiper();
      await s.slidePrev();
    };

    const slideNext = async () => {
      const s = await refSlides?.value?.$el.getSwiper();
      await s.slideNext();
    };

    const slideChanged = async (event: any) => {
      let popup = 0;
      if (event) {
        await event.target.getActiveIndex().then((i: any) => {
          popup = i;
        });
      }
      if (popup == 0) {
        state.isNextBtn = true;
        state.isPrevBtn = false;
        if (popup >= state.popup.items.length - 1) {
          state.isNextBtn = false;
          state.isPrevBtn = false;
        }
      } else if (popup < state.popup.items.length - 1) {
        state.isNextBtn = true;
        state.isPrevBtn = true;
      } else {
        state.isNextBtn = false;
        state.isPrevBtn = true;
      }
    };

    const fetch = async () => {
      if (state.popup.items.length <= 1) {
        state.isNextBtn = false;
        state.isPrevBtn = false;
      }
    };

    const onClose = async (date: boolean) => {
      if (date) {
        if (state.popupName == "popup") {
          const newDate = new Date();
          const year = newDate.getFullYear();
          const month =
            9 < newDate.getMonth() + 1
              ? newDate.getMonth() + 1
              : `0${newDate.getMonth() + 1}`;
          const day =
            9 < newDate.getDate() ? newDate.getDate() : `0${newDate.getDate()}`;
          const hours =
            9 < newDate.getHours()
              ? newDate.getHours()
              : `0${newDate.getHours()}`;
          const minutes =
            9 < newDate.getMinutes()
              ? newDate.getMinutes()
              : `0${newDate.getMinutes()}`;
          const seconds =
            9 < newDate.getSeconds()
              ? newDate.getSeconds()
              : `0${newDate.getSeconds()}`;
          store.commit("popupDate", {
            date: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
          });
          console.log("datedatedatedatedatedatedate");
        } else if (state.popupName == "tutorial") {
          store.commit("tutorial", {
            version: `${state.version.number}_${state.version.code}`,
          });
        }
      }

      modalController.dismiss({
        popupName: props.popupName,
      });
    };

    onMounted(() => {
      fetch();
    });

    return {
      t,
      state,
      onClose,
      refSlides,
      slidePrev,
      slideNext,
      slideChanged,
    };
  },
});
