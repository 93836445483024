const ID_DOWNLOAD_KEY = "id_download";
const ID_POPUP_KEY = "id_popup";

export const getDownloadDate = () => {
  return window.localStorage.getItem(ID_DOWNLOAD_KEY);
};

export const saveDownloadDate = (date: string) => {
  window.localStorage.setItem(ID_DOWNLOAD_KEY, date);
};

export const getPopupDate = () => {
  return window.localStorage.getItem(ID_POPUP_KEY);
};

export const savePopupDate = (date: string) => {
  window.localStorage.setItem(ID_POPUP_KEY, date);
};

export default {
  getDownloadDate,
  saveDownloadDate,
  getPopupDate,
  savePopupDate
};
