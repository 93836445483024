const ID_LOCATION_KEY = "id_location";

export const getLocation = () => {
  const data = {
    lat: 0,
    lng: 0,
    date: 0
  }

  try {
    const locationData = JSON.parse(String(window.localStorage.getItem(ID_LOCATION_KEY)))
    console.log(locationData);
    console.log(locationData.lat);
    data.lat = locationData.lat;
    data.lng = locationData.lng;
    data.date = locationData.date;
  } catch (e) {
    data.lat = 0;
    data.lng = 0;
    data.date = 0;
  }

  return data;
};

export const saveLocation = (location: string) => {
  return window.localStorage.setItem(ID_LOCATION_KEY, location)
}

export default {
  getLocation,
  saveLocation
}