import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/like", {
    params: params,
    headers: authHeader()
  });
};

const postFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/like/post/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const findone = (id: number) => {
  return http.get(SERVICE_API_URL + `/like/${id}`, {
    headers: authHeader()
  });
};

const add = (params: object) => {
  return http.post(SERVICE_API_URL + "/like", params, {
    headers: authHeader()
  });
};

const modify = (id: number, params: object ) => {
  return http.put(SERVICE_API_URL + `/like/${id}`, params, {
    headers: authHeader()
  });
};

const remove = (params: object) => {
  return http.delete(SERVICE_API_URL + "/like", {
    data: params,
    headers: authHeader()
  });
};

export default {
  findAll,
  postFindone,
  findone,
  add,
  modify,
  remove
}