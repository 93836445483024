import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const inquiryAdd = (params: object) => {
  return http.post(SERVICE_API_URL + `/inquiry`, params, {
    headers: authHeader()
  });
};

export default {
  inquiryAdd
}