import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/festival", {
    params: params,
    headers: authHeader()
  });
};

const feedFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/festival/feed", {
    params: params,
    headers: authHeader()
  });
};
const recommendFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/festival/recommend", {
    params: params,
    headers: authHeader()
  });
};

const findone = (id: number) => {
  return http.get(SERVICE_API_URL + `/festival/${id}`, {
    headers: authHeader()
  });
};

const festivalProgress = (params: object) => {
  return http.get(SERVICE_API_URL + "/festival/progress", {
    params: params,
    headers: authHeader()
  });
};
const festivalStandby = (params: object) => {
  return http.get(SERVICE_API_URL + "/festival/standby", {
    params: params,
    headers: authHeader()
  });
};
const festivalEnd = (params: object) => {
  return http.get(SERVICE_API_URL + "/festival/end", {
    params: params,
    headers: authHeader()
  });
};

export default {
  findAll,
  findone,
  feedFindAll,
  recommendFindAll,
  festivalProgress,
  festivalStandby,
  festivalEnd
}