import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const districtsFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/group/districts", {
    params: params,
    headers: authHeader()
  });
};

const categoryFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/group/category", {
    params: params,
    headers: authHeader()
  });
};

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/group", {
    params: params,
    headers: authHeader()
  });
};

const feedFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/group/feed", {
    params: params,
    headers: authHeader()
  });
};

const findone = (id: number) => {
  return http.get(SERVICE_API_URL + `/group/${id}`, {
    headers: authHeader()
  });
};

export default {
  districtsFindAll,
  categoryFindAll,
  findAll,
  feedFindAll,
  findone
}