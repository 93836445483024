
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { IonButton } from "@ionic/vue";

export default defineComponent({
  components: {
    IonButton,
  },
  name: "Empty",
  props: {
    message: {
      type: String,
      default: "Empty",
      description: "message (default -> 알림)",
    },
    isbtn: {
      type: Boolean,
      default: false,
      description: "isbtn (default -> false)",
    },
    isFixCenter: {
      type: Boolean,
      default: true,
      description: "isbtn (default -> false)",
    },
  },
  setup() {
    const router = useRouter();

    const refreshAll = () => {
      //  router.go(0);
    };
    return { refreshAll };
  },
});
