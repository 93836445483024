import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/comment", {
    params: params,
    headers: authHeader()
  });
};

const postFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/comment/post/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const findone = (id: number) => {
  return http.get(SERVICE_API_URL + `/comment/${id}`, {
    headers: authHeader()
  });
};

const commentadd = (params: object) => {
  return http.post(SERVICE_API_URL + "/comment", params, {
    headers: authHeader()
  });
};

const modify = (id: number, params: object ) => {
  return http.put(SERVICE_API_URL + `/comment/${id}`, params, {
    headers: authHeader()
  });
};
const remove = (id: number) => {
  return http.delete(SERVICE_API_URL + `/comment/${id}`, {
    headers: authHeader()
  });
};

export default {
  findAll,
  postFindone,
  findone,
  commentadd,
  modify,
  remove
}