import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/event", {
    params: params,
    headers: authHeader()
  });
};

const feedFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/event/feed", {
    params: params,
    headers: authHeader()
  });
};
const eventProgress = (params: object) => {
  return http.get(SERVICE_API_URL + "/event/progress", {
    params: params,
    headers: authHeader()
  });
};
const eventEnd = (params: object) => {
  return http.get(SERVICE_API_URL + "/event/end", {
    params: params,
    headers: authHeader()
  });
};

const findone = (id: number) => {
  return http.get(SERVICE_API_URL + `/event/${id}`, {
    headers: authHeader()
  });
};

const recommendfindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/event/recommend", {
    params: params,
    headers: authHeader()
  });
};




export default {
  findAll,
  feedFindAll,
  findone,
  recommendfindAll,
  eventProgress,
  eventEnd
}