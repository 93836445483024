
import { IonContent, modalController } from "@ionic/vue";
import { AppLauncher } from "@capacitor/app-launcher";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "AppDownloadgo",
  components: { IonContent },
  props: {
    os: {
      type: String,
      default: null,
      description: "(default -> os)",
    },
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    console.log(store);
    /*
    const checkClose = () => {
      modalController.dismiss();
     store.getters.getdat
    }
    */
    const onClose = async (date: boolean) => {
      modalController.dismiss();
      if (date) {
        const newDate = new Date();
        const year = newDate.getFullYear();
        const month =
          9 < newDate.getMonth() + 1
            ? newDate.getMonth() + 1
            : `0${newDate.getMonth() + 1}`;
        const day =
          9 < newDate.getDate() ? newDate.getDate() : `0${newDate.getDate()}`;
        const hours =
          9 < newDate.getHours()
            ? newDate.getHours()
            : `0${newDate.getHours()}`;
        const minutes =
          9 < newDate.getMinutes()
            ? newDate.getMinutes()
            : `0${newDate.getMinutes()}`;
        const seconds =
          9 < newDate.getSeconds()
            ? newDate.getSeconds()
            : `0${newDate.getSeconds()}`;
        store.commit("downloadDate", {
          date: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
        });
        console.log("datedatedatedatedatedatedate");
      }
    };
    const onAppLauncher = async () => {
      if (props.os == "ios") {
        await AppLauncher.openUrl({
          url: "itms-apps://itunes.apple.com/app/id1207835232",
        });
      } else {
        await AppLauncher.openUrl({
          url: "market://details?id=kr.nextinnovation.stamppop",
        });
      }
    };
    return { onClose, onAppLauncher, t };
  },
});
