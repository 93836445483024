import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/address", {
    params: params,
    headers: authHeader()
  });
};

export default {
  findAll,
}