import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/post", {
    params: params,
    headers: authHeader()
  });
};

const communityFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/post/community", {
    params: params,
    headers: authHeader()
  });
};

const likeFindOne = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/post/like/${id}`, {
    params: params,
    headers: authHeader()
  });
};


const postFindone = (id: number) => {
  return http.get(SERVICE_API_URL + `/post/${id}`, {
    headers: authHeader()
  });
};

const userFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/post/user/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const add = (params: object) => {
  return http.post(SERVICE_API_URL + "/post", params, {
    headers: authHeader()
  });
};

const modify = (id: number, params: object ) => {
  return http.put(SERVICE_API_URL + `/post/${id}`, params, {
    headers: authHeader()
  });
};
const remove = (id: number) => {
  return http.delete(SERVICE_API_URL + `/post/${id}`, {
    headers: authHeader()
  });
};

export default {
  findAll,
  postFindone,
  userFindone,
  likeFindOne,
  communityFindAll,
  add,
  modify,
  remove
}