import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const contentFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/banner/content", {
    params: params,
    headers: authHeader()
  });
};

const generalFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/banner/general", {
    params: params,
    headers: authHeader()
  });
};

const feedFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/banner/feed", {
    params: params,
    headers: authHeader()
  });
};

const findone = (id: number) => {
  return http.get(SERVICE_API_URL + `/banner/${id}`, {
    headers: authHeader()
  });
};

export default {
  contentFindAll,
  generalFindAll,
  feedFindAll,
  findone
}