import { createStore } from "vuex";
import Jwt from "@/common/jwt";
import Popup from "@/common/popup";
import Setting from "@/common/setting";
import location from "@/common/location";

/**
 * services
 */
import services from "@/services";
// import { registerRuntimeCompiler } from "vue";

export default createStore({
  state: {
    token: {
      accessToken: Jwt.getAccessToken(),
    },
    user: Jwt.getAccessToken(),
    isAuthenticated: !!Jwt.getAccessToken(),
    geolocation: location.getLocation(),
    language: Setting.getLanguage(),
    markersLocation: {
      address: "",
      lat: 0,
      lng: 0,
    },
    popup: {
      date: Popup.getPopupDate(),
    },
    download: {
      date: Popup.getDownloadDate(),
    },
    tutorial: {
      version: Setting.getTutorial(),
    },
    page: {
      title: "",
    },
    report: {
      tpye: "",
      id: "",
    },
    review: {
      write: false,
    },
  },
  getters: {
    getReview: function(state) {
      return state.review;
    },
    getGeoLocation: function(state) {
      if (state.geolocation.date) {
        const nowDate = new Date();
        const locationDate = new Date(state.geolocation.date);
        console.log(locationDate);
        const elapsedMSec = nowDate.getTime() - locationDate.getTime();
        const elapsedMinute = elapsedMSec / 1000 / 60;
        if (5 <= elapsedMinute) {
          // 위치정보를 받은지 5분이 지났을때 위치정보 초기화
          state.geolocation.lat = 0;
          state.geolocation.lng = 0;
        }
      }

      return state.geolocation;
    },
    getMarkersLocation: function(state) {
      return state.markersLocation;
    },
    getLanguage: function(state) {
      return state.language;
    },
    getTutorial: function(state) {
      return state.tutorial.version;
    },
    getAccessToken: function(state) {
      return state.token.accessToken;
    },
    getUser: function(state) {
      return state.user;
    },
    isAuthenticated: function(state) {
      return state.isAuthenticated;
    },
    getDownloadDate: function(state) {
      return state.download.date;
    },
    getPopupDate: function(state) {
      return state.popup.date;
    },
    getPage: function(state) {
      // 페이지 정보를 반환한다.
      return state.page;
    },
    getreport: function(state) {
      return state.report;
    },
  },
  mutations: {
    review: function(state: any, payload = {}) {
      state.review.write = payload.write;
    },
    markersLocation: function(state: any, payload = {}) {
      state.markersLocation.address = payload.address;
      state.markersLocation.lat = payload.lat;
      state.markersLocation.lng = payload.lng;
    },
    geolocation: function(state: any, payload = {}) {
      console.log("geolocation : payload --");
      console.log(payload);
      state.geolocation.lat = payload.lat;
      state.geolocation.lng = payload.lng;

      const newDate = new Date();
      const year = newDate.getFullYear();
      const month =
        9 < newDate.getMonth() + 1
          ? newDate.getMonth() + 1
          : `0${newDate.getMonth() + 1}`;
      const day =
        9 < newDate.getDate() ? newDate.getDate() : `0${newDate.getDate()}`;
      const hours =
        9 < newDate.getHours() ? newDate.getHours() : `0${newDate.getHours()}`;
      const minutes =
        9 < newDate.getMinutes()
          ? newDate.getMinutes()
          : `0${newDate.getMinutes()}`;
      const seconds =
        9 < newDate.getSeconds()
          ? newDate.getSeconds()
          : `0${newDate.getSeconds()}`;
      state.geolocation.date = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      location.saveLocation(JSON.stringify(state.geolocation));
      state.geolocation = JSON.parse(location.getLocation().toString());
      console.log("======================");
      console.log(state.geolocation);
    },
    login: function(state: any, payload = {}) {
      state.token.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      Jwt.saveAccessToken(payload.accessToken);
    },
    language: function(state: any, payload = {}) {
      state.language = payload.language;
      Setting.saveLanguage(payload.language);
    },
    tutorial: function(state: any, payload = {}) {
      state.tutorial.version = payload.version;
      Setting.saveTutorial(payload.version);
    },
    logout: function(state: any) {
      state.token.accessToken = "";
      state.isAuthenticated = false;
      Jwt.destroyAccessToken();
    },
    downloadDate(state: any, payload = {}) {
      console.log(state);
      console.log(payload);
      state.download.date = payload.date;
      Popup.saveDownloadDate(payload.date);
    },
    popupDate(state: any, payload = {}) {
      console.log(state);
      console.log(payload);
      state.popup.date = payload.date;
      Popup.savePopupDate(payload.date);
    },
    page(state: any, payload = {}) {
      console.log(state);
      console.log(payload);
      state.page.title = payload.title; // 페이지 타이틀을 저장한다.
    },
    report(state: any, payload = {}) {
      console.log(state);
      console.log(payload);
      state.report.tpye = payload.tpye;
      state.report.id = payload.id;
    },
  },
  actions: {
    logout: function(context) {
      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);

        services.authLogout().then(
          (response) => {
            const { data } = response;
            if (response.status == 204) {
              console.log(context);
              console.log(data);
              context.commit("logout");
            }

            resolve(response);
          },
          (error) => {
            // status 404
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
    login: function(context, payload) {
      const params: any = {};

      params.loginid = payload.loginid;
      params.pass = payload.pass;

      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);

        services.authLogin(params).then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              console.log(context);
              console.log(data);
              context.commit("login", {
                accessToken: data.accessToken,
              });
            }

            resolve(response);
          },
          (error) => {
            // status 404
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
    register: function(context, payload) {
      const params: any = {};

      params.loginid = payload.loginid;
      params.email = payload.email;
      params.pass = payload.pass;
      params.name = payload.name;
      params.termsAgree = payload.termsAgree;
      params.privacyAgree = payload.privacyAgree;
      params.thirdpartyAgree = payload.thirdpartyAgree;
      params.locationAgree = payload.locationAgree;
      params.marketingAgree = payload.marketingAgree;

      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);

        services.authRegister(params).then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              console.log(context);
              console.log(data);
              context.commit("login", {
                accessToken: data.accessToken,
              });
            }
            resolve(response);
          },
          (error) => {
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
    recover: function(context, payload) {
      const params: any = {};
      params.email = payload.email;

      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);
        services.authRecover(params).then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              console.log(context);
              console.log(data);
            }
            resolve(response);
          },
          (error) => {
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
  },
});
