import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home/Home.vue'

// layout
import MainLayout from "../layout/MainLayout.vue";
import PopupLayout from "../layout/PopupLayout.vue";

// store
import store from "../store";

const title = "스탬프 팝";

const routes = [
  {
    path: '/',
    redirect: "/home",
    component: MainLayout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          authRequired: true,
          title: title + "홈",
          breadcrumb: [{ name: "홈", link: "" }]
        }
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@/views/Search/index.vue'),
        meta: {
          authRequired: true,
          title: title + "검색",
          breadcrumb: [{ name: "", link: "" }]
        }
      },
      {
        path: '/mission',
        name: 'mission',
        component: () => import('@/views/Mission/Mission.vue'),
        meta: {
          authRequired: true,
          title: title + "홈",
          breadcrumb: [{ name: "미션", link: "" }]
        }
      },
      {
        path: '/community',
        name: 'community',
        component: () => import('@/views/Community/Community.vue'),
        meta: {
          authRequired: true,
          title: title + "홈",
          breadcrumb: [{ name: "커뮤니티", link: "" }]
        },
        props: true,
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/User/User.vue'),
        meta: {
          authRequired: true,
          title: title + "홈",
          breadcrumb: [{ name: "마이페이지", link: "" }]
        }
      },
    ]
  },
  //Auth
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Auth/Login/Login.vue'),
    meta: {
      authRequired: false,
    }
  },
  {
    path: '/recover',
    name: 'recover',
    component: () => import('@/views/Auth/Recover/Recover.vue'),
    meta: {
      authRequired: false,
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Auth/Register/Register.vue'),
    meta: {
      authRequired: false,
    }
  },
  {
    path: '/facebook',
    name: 'facebook',
    component: () => import('@/views/Auth/Register/FacebookRegister.vue'),
    meta: {
      authRequired: false,
    }
  },
  //Terms
  {
    path: '/terms/maketing',
    name: 'TermsMaketing',
    component: () => import('@/views/Terms/Maketing/Maketing.vue'),
    meta: {
      title: title + "홈",
      breadcrumb: [{ name: "마케팅 수신 동의", link: "" }]
    }
  },
  {
    path: '/terms/third-party',
    name: 'TermsThirdParty',
    component: () => import('@/views/Terms/Third-party/Third-party.vue'),
    meta: {
      title: title + "홈",
      breadcrumb: [{ name: "제3자 제공동의", link: "" }]
    }
  },
  {
    path: '/terms/privacy',
    name: 'TermsPrivacy',
    component: () => import('@/views/Terms/Privacy/Privacy.vue'),
    meta: {
      title: title + "홈",
      breadcrumb: [{ name: "개인정보", link: "" }]
    }
  },
  {
    path: '/terms/provision',
    name: 'TermsProvision',
    component: () => import('@/views/Terms/Provision/Provision.vue'),
    meta: {
      title: title + "홈",
      breadcrumb: [{ name: "이용약관", link: "" }]
    }
  },
  {
    path: '/festival',
    name: 'Festival',
    component: () => import('@/views/Festival/index.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "축제", link: "" }]
    },
    props: true,
  },
  {
    path: '/festival/:id',
    name: 'FestivalId',
    component: () => import('@/views/Festival/Id/Festival.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "축제 상세보기", link: "" }]
    },
    props: true,
  },
  // 게시글 상세보기
  {
    path: '/post/:id',
    name: 'PostId',
    component: () => import('@/views/Post/Id/PostId.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "커뮤니티", link: "" }]
    },
    props: true,
  },
  {
    path: '/post/editor',
    name: 'PostEditor',
    component: () => import('@/views/Post/Editor/Editor.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "글 작성", link: "" }]
    }
  },
  {
    path: '/post/:id/like',
    name: 'PostIdLike',
    component: () => import('@/views/Post/Id/Like/Like.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "글 작성", link: "" }]
    },
    props: true,
  },
  {
    path: '/post/:id/comment',
    name: 'PostIdComment',
    component: () => import('@/views/Post/Id/Comment/Comment.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "글 작성", link: "" }]
    },
    props: true,
  },
  //마이페이지 서브페이지
  {
    path: '/user/:id/post/like',
    name: 'UserIdPostLike',
    component: () => import('@/views/User/Id/Post/Like/Like.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "좋아한 게시물", link: "" }]
    },
    props: true,
  },
  {
    path: '/user/:id/mission',
    name: 'UserIdMission',
    component: () => import('@/views/User/Id/Mission/Mission.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "미션 진행중", link: "" }]
    },
    props: true,
  },
  {
    path: '/user/:id/stamp',
    name: 'UserIdStamp',
    component: () => import('@/views/User/Id/Stamp/Stamp.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "스탬프", link: "" }]
    },
    props: true,
  },
  {
    path: '/user/:id/gift',
    name: 'UserIdGift',
    component: () => import('@/views/User/Id/Gift/Gift.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "리워드(선물) 신청하기", link: "" }]
    },
    props: true,
  },
  {
    path: '/user/:id/follow',
    name: 'UserIdFollow',
    component: () => import('@/views/User/Id/Follow/Follow.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "리워드(선물) 신청하기", link: "" }]
    },
    props: true,
  },
  {
    path: '/user/:id',
    name: 'UserId',
    component: () => import('@/views/User/User.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "마이페이지", link: "" }]
    },
    props: true,
  },

  // 이벤트
  {
    path: '/event',
    name: 'Event',
    component: () => import('@/views/Event/Event.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "이벤트 진행중", link: "" }]
    }
  },
  {
    path: '/event/:id',
    name: 'EventId',
    component: () => import('@/views/Event/Id/View.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "이벤트 상세보기", link: "" }]
    },
    props: true,
  },

  //스탬프 찾기
  {
    path: '/radar',
    name: 'Radar',
    component: () => import('@/views/Radar/Find.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "스탬프 찾기", link: "" }]
    }
  },
  {
    path: '/affiliateinquiry',
    name: 'AffiliateInquiry',
    component: () => import('@/views/Affiliate-inquiry/AffiliateInquiry.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "입점제휴문의", link: "" }]
    }
  },
  {
    path: '/mission/:id',
    name: 'MissionId',
    component: () => import('@/views/Mission/Id/MissionId.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "미션 상세보기", link: "" }]
    },
    props: true,
  },
  {
    path: '/mission/:id/stamp',
    name: 'MissionIdStamp',
    component: () => import('@/views/Mission/Id/Stamp/MissionIdStamp.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "리워드/배지 상세보기-스탬프", link: "" }]
    },
    props: true,
  },
  {
    path: '/mission/:id/application',
    name: 'MissionIdApplication',
    component: () => import('@/views/Mission/Id/Application/Application.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "리워드/배지 상세보기-스탬프", link: "" }]
    },
    props: true,
  },
  {
    path: '/mission/review/:id',
    name: 'MissionReviewId',
    component: () => import('@/views/Mission/Review/index.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "미션리뷰작성", link: "" }]
    },
    props: true,
  },
  {
    path: '/stamp/:id',
    name: 'StampId',
    component: () => import('@/views/Stamp/Id/StampId.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "스탬프상세보기", link: "" }]
    },
    props: true,
  },
  {
    path: '/stamp/:id/map',
    name: 'StampIdMap',
    component: () => import('@/views/Stamp/Id/Map/Map.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "스탬프상세보기 지도", link: "" }]
    },
    props: true,
  },
  {
    path: '/stamp/review/:id',
    name: 'StampReviewId',
    component: () => import('@/views/Stamp/Review/index.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "스탬프리뷰작성", link: "" }]
    },
    props: true,
  },
  {
    path: '/stamp/request',
    name: 'StampRequest',
    component: () => import('@/views/Request/index.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "스탬프 요청", link: "" }]
    },
    props: true,
  },
  {
    path: '/page/mission-reward',
    name: 'PageMissionReward',
    component: () => import('@/views/Page/Mission-reward/Mission-reward.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "미션선물신청방법", link: "" }]
    }
  },
  {
    path: '/page/enjoy',
    name: 'PageEnjoy',
    component: () => import('@/views/Page/Enjoy/Enjoy.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "스탬프팝 즐기는 방법!", link: "" }]
    }
  },
  {
    path: '/page/upgrademission',
    name: 'PageUpgradeMission',
    component: () => import('@/views/Page/UpgradeMission/UpgradeMission.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "레벨로 업그레이드 된 미션 도전!", link: "" }]
    }
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/Setting/Setting.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "설정", link: "" }]
    }
  },
  {
    path: '/setting/profile',
    name: 'SettingProfile',
    component: () => import('@/views/Setting/Profile/Profile.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "설정 개인정보 수정", link: "" }]
    }
  },
  {
    path: '/setting/sns',
    name: 'SettingSns',
    component: () => import('@/views/Setting/Sns/Sns.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "설정 연결된 계정", link: "" }]
    }
  },
  {
    path: '/setting/notification',
    name: 'SettingNotification',
    component: () => import('@/views/Setting/Notification/Notification.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "알람 설정", link: "" }]
    }
  },
  {
    path: '/setting/block',
    name: 'SettingBlock',
    component: () => import('@/views/Setting/Block/Block.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "설정 차단한 사용자 관리", link: "" }]
    }
  },
  {
    path: '/setting/report',
    name: 'SettingReport',
    component: () => import('@/views/Setting/Report/Report.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "문제 신고", link: "" }]
    },
    props: true,
  },
  {
    path: '/setting/withdrawal',
    name: 'SettingWithdrawal',
    component: () => import('@/views/Setting/Withdrawal/Withdrawal.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "회원 탈퇴", link: "" }]
    },
    props: true,
  },
  {
    path: '/setting/email',
    name: 'SettingEmail',
    component: () => import('@/views/Setting/Email/Email.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "이메일 수정", link: "" }]
    },
    props: true,
  },
  {
    path: '/setting/loginid',
    name: 'SettingLoginid',
    component: () => import('@/views/Setting/Loginid/Loginid.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "아이디 수정", link: "" }]
    },
    props: true,
  },
  {
    path: '/setting/password',
    name: 'SettingPassword',
    component: () => import('@/views/Setting/Password/Password.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "비밀번호 수정", link: "" }]
    },
    props: true,
  },
  {
    path: '/group/:id',
    name: 'GroupId',
    component: () => import('@/views/Group/Id/GroupId.vue'),
    meta: {
      authRequired: true,
      title: title + "홈",
      breadcrumb: [{ name: "그룹스탬프(행정구역)", link: "" }]
    },
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log(store.getters["isAuthenticated"]);
  const isAuthenticated = store.getters["isAuthenticated"];
  console.log(from);
  if (
    to.matched.some(function(routeInfo) {
      return routeInfo.meta.authRequired;
    })
  ) {
    // 인증이 필요한 페이지일 경우 인증 체크
    if (isAuthenticated) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    if (Object.prototype.hasOwnProperty.call(to.meta, "authRequired")) {
      if (isAuthenticated) {
        next({ name: "home" });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router
