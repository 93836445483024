import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const add = (params: any) => {
  return http.post(SERVICE_API_URL + `/file`, params, {
    headers: authHeader()
  });
};

export default {
  add
}