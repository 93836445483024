const ID_LANGUAGE_KEY = "id_language";
const ID_TUTORIAL_KEY = "id_tutorial";

export const getTutorial = () => {
  return window.localStorage.getItem(ID_TUTORIAL_KEY);
};

export const saveTutorial = (date: string) => {
  window.localStorage.setItem(ID_TUTORIAL_KEY, date);
};

export const destroyTutorial = () => {
  window.localStorage.removeItem(ID_TUTORIAL_KEY);
};

export const getLanguage = () => {
  return window.localStorage.getItem(ID_LANGUAGE_KEY);
};

export const saveLanguage = (language: string) => {
  window.localStorage.setItem(ID_LANGUAGE_KEY, language);
};

export const destroyLanguage = () => {
  console.log("destroyAccessToken");
  window.localStorage.removeItem(ID_LANGUAGE_KEY);
};

export default {
  getTutorial,
  saveTutorial,
  destroyTutorial,
  getLanguage,
  saveLanguage,
  destroyLanguage
};
