import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const login = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/login", params, {
    headers: authHeader()
  });
};

const register = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/register", params, {
    headers: authHeader()
  });
};

const email = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/email", params, {
    headers: authHeader()
  });
};

const user = () => {
  return http.get(SERVICE_API_URL + "/auth/user", {
    headers: authHeader()
  });
};

const recover = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/recover", params, {
    headers: authHeader()
  });
};

const emailGet = (params: object) => {
  return http.get(SERVICE_API_URL + "/auth/email", {
    params: params,
    headers: authHeader()
  });
};
const loginid = (params: object) => {
  return http.get(SERVICE_API_URL + "/auth/loginid", {
    params: params,
    headers: authHeader()
  });
};

const logout = () => {
  return http.post(SERVICE_API_URL + "/auth/logout", {
    headers: authHeader()
  });
};

const withdrawal = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/withdrawal", params, {
    headers: authHeader()
  });
};

export default {
  login,
  logout,
  register,
  email,
  emailGet,
  loginid,
  recover,
  user,
  withdrawal
}