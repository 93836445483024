import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/user", {
    params: params,
    headers: authHeader()
  });
};
const userPatch = (params: object) => {
  return http.patch(SERVICE_API_URL + "/user", params, {
    headers: authHeader()
  });
};

const feedFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/user/feed", {
    params: params,
    headers: authHeader()
  });
};

const findOne = (id: number) => {
  return http.get(SERVICE_API_URL + `/user/${id}`, {
    headers: authHeader()
  });
};

const followFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/user/follow/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const followerFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/user/follower/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const followAdd = (params: object) => {
  return http.post(SERVICE_API_URL + `/user/follow`, params, {
    headers: authHeader()
  });
};

const followRemove = (params: object) => {
  return http.delete(SERVICE_API_URL + `/user/follow`, {
    data: params,
    headers: authHeader()
  });
};

const blockFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/user/block/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const blockAdd = (params: object) => {
  return http.post(SERVICE_API_URL + `/user/block`, params, {
    headers: authHeader()
  });
};

const blockRemove = (params: object) => {
  return http.delete(SERVICE_API_URL + `/user/block`, {
    data: params,
    headers: authHeader()
  });
};

export default {
  findAll,
  feedFindAll,
  findOne,
  followFindone,
  followerFindone,
  userPatch,
  followAdd,
  followRemove,
  blockFindone,
  blockAdd,
  blockRemove
}