import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/mission", {
    params: params,
    headers: authHeader()
  });
};

const feedFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/mission/feed", {
    params: params,
    headers: authHeader()
  });
};

const userFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/mission/user", {
    params: params,
    headers: authHeader()
  });
};

const findone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/mission/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const relationFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/mission/relation/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const userFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/mission/user/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const userCompleteFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/mission/user/complete/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const userProgressFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/mission/user/progress/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const userRewardFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/mission/user/reward/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const completeAdd = (params: object) => {
  return http.post(SERVICE_API_URL + "/mission/complete", params, {
    headers: authHeader()
  });
};

const telAdd = (params: object) => {
  return http.post(SERVICE_API_URL + "/mission/tel", params, {
    headers: authHeader()
  });
};

const regionFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/mission/region", {
    params: params,
    headers: authHeader()
  });
};

const badgeFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/mission/badge", {
    params: params,
    headers: authHeader()
  });
};

const rewardFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/mission/reward", {
    params: params,
    headers: authHeader()
  });
};

const reviewAdd = (params: object) => {
  return http.post(SERVICE_API_URL + "/mission/review", params, {
    headers: authHeader()
  });
};

const reviewFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/mission/review", {
    params:params,
    headers: authHeader()
  });
};

const reviewRemove = (id: number) => {
  return http.delete(SERVICE_API_URL + `/mission/review/${id}`, {
    headers: authHeader()
  });
};

export default {
  findAll,
  feedFindAll,
  findone,
  relationFindone,
  userCompleteFindone,
  userProgressFindone,
  userRewardFindone,
  userFindAll,
  userFindone,
  completeAdd,
  telAdd,
  regionFindAll,
  badgeFindAll,
  rewardFindAll,
  reviewAdd,
  reviewFindAll,
  reviewRemove
}