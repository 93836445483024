import authService from "./auth.service";
import groupService from "./group.service";
import bannerService from "./banner.service";
import missionService from "./mission.service";
import postService from "./post.service";
import stampService from "./stamp.service";
import userService from "./user.service";
import regionService from "./region.service";
import likeService from "./like.service";
import commentService from "./comment.service";
import eventService from "./event.service";
import festivalService from "./festival.service";
import reportService from "./report.service";
import inquiryService from "./inquiry.service";
import fileService from "./file.service";
import advertisementService from "./advertisement.service";
import appService from "./app.service";
import requestService from "./request.service";
import popupService from "./popup.service";
import AddressService from "./address.service";

export default {
  // addressService
  addressFindAll(params: object = {}) {
    return AddressService.findAll(params);
  },
  // popupService
  popupFindAll(params: object = {}) {
    return popupService.findAll(params)
  },
  // appService
  appFindone(id: string, params: object = {}) {
    return appService.findOne(id, params);
  },
  findWeather() {
    return appService.findWeather();
  },
  // fileService
  fileAdd(params: object = {}) {
    return fileService.add(params);
  },
  // authService
  authLogin(params: object = {}) {
    return authService.login(params);
  },
  authRegister(params: object = {}) {
    return authService.register(params);
  },
  authRecover(params: object = {}) {
    return authService.recover(params);
  },
  authUser() {
    return authService.user();
  },
  authWithdrawal(params: object = {}) {
    return authService.withdrawal(params);
  },
  authLogout() {
    return authService.logout();
  },
  // groupService
  groupDistrictsFineAll(params: object = {}) {
    return groupService.districtsFindAll(params);
  },
  groupCategoryFindAll(params: object = {}) {
    return groupService.categoryFindAll(params);
  },
  groupFindones(id: number) {
    return groupService.findone(id);
  },
  // bannerService
  bannerContentFineAll(params: object = {}) {
    return bannerService.contentFindAll(params);
  },
  bannerGeneralFineAll(params: object = {}) {
    return bannerService.generalFindAll(params);
  },
  bannerFeedFineAll(params: object = {}) {
    return bannerService.feedFindAll(params);
  },
  // missionService
  missionFineAll(params: object = {}) {
    return missionService.findAll(params);
  },
  missionFeedFineAll(params: object = {}) {
    return missionService.feedFindAll(params);
  },
  relationFindone(id: number, params: object = {}) {
    return missionService.relationFindone(id, params);
  },
  missionFindones(id: number, params: object = {}) {
    return missionService.findone(id, params);
  },
  missionUserCompleteFindone(id: number, params: object = {}) {
    return missionService.userCompleteFindone(id, params);
  },
  missionUserProgressFindone(id: number, params: object = {}) {
    return missionService.userProgressFindone(id, params);
  },
  missionUserRewardFindone(id: number, params: object = {}) {
    return missionService.userRewardFindone(id, params);
  },
  missionCompleteAdd(params: object = {}) {
    return missionService.completeAdd(params);
  },
  missionTelAdd(params: object = {}) {
    return missionService.telAdd(params);
  },
  missionRegionFindAll(params: object = {}) {
    return missionService.regionFindAll(params);
  },
  missionBadgeFindAll(params: object = {}) {
    return missionService.badgeFindAll(params);
  },
  missionRewardFindAll(params: object = {}) {
    return missionService.rewardFindAll(params);
  },
  missionReviewAdd(params: object = {}) {
    return missionService.reviewAdd(params);
  },
  missionReviewFindAll(params: object = {}) {
    return missionService.reviewFindAll(params);
  },
  missionReviewRemove(id: number) {
    return missionService.reviewRemove(id);
  },
  // postService
  postCommunityFindAll(params: object = {}) {
    return postService.communityFindAll(params);
  },
  postFindAll(params: object = {}) {
    return postService.findAll(params);
  },
  postUserFindone(id: number, params: object = {}) {
    return postService.userFindone(id, params);
  },
  postFindones(id: number) {
    return postService.postFindone(id);
  },
  postAdd(params: object = {}) {
    return postService.add(params);
  },
  postRemove(id: number) {
    return postService.remove(id);
  },
  postLikeFindone(id: number, params: object = {}) {
    return postService.likeFindOne(id, params);
  },
  // stampService
  stampFindAll(params: object = {}) {
    return stampService.findAll(params);
  },
  stampgroupFindone(id: number, params: object = {}) {
    return stampService.groupFindone(id, params);
  },
  stampFindone(id: number) {
    return stampService.findone(id);
  },
  stampUserFindone(id: number, params: object = {}) {
    return stampService.userFindone(id, params);
  },
  stampMissionFindone(id: number, params: object = {}) {
    return stampService.missionFindone(id, params);
  },
  stampAcquisitionFindAll(params: object = {}) {
    return stampService.acquisitionFindAll(params);
  },
  stampAcquisitionAdd(params: object = {}) {
    return stampService.acquisitionAdd(params);
  },
  stampAcquisitionFindone(id: number) {
    return stampService.acquisitionFindone(id);
  },
  stampReviewFindAll(params: object = {}) {
    return stampService.reviewFindAll(params);
  },
  stampReviewAdd(params: object = {}) {
    return stampService.reviewAdd(params);
  },
  stampReviewRemove(id: number) {
    return stampService.reviewRemove(id);
  },
  //userService
  userfindOne(id: number) {
    return userService.findOne(id);
  },
  userPatch(params: object = {}) {
    return userService.userPatch(params);
  },
  followOne(id: number, params: object = {}) {
    return userService.followFindone(id, params);
  },
  followerOne(id: number, params: object = {}) {
    return userService.followerFindone(id, params);
  },
  followAdd(params: object = {}) {
    return userService.followAdd(params);
  },
  followRemove(params: object = {}) {
    return userService.followRemove(params);
  },
  blockOne(id: number, params: object = {}) {
    return userService.blockFindone(id, params);
  },
  blockAdd(params: object = {}) {
    return userService.blockAdd(params);
  },
  blockRemove(params: object = {}) {
    return userService.blockRemove(params);
  },
  //regionService
  regionFindall(params: object = {}) {
    return regionService.findall(params);
  },
  //likeService
  likeAdd(params: object = {}) {
    return likeService.add(params);
  },
  likeRemove(params: object = {}) {
    return likeService.remove(params);
  },
  likePostFindone(id: number, params: object = {}) {
    return likeService.postFindone(id, params);
  },
  //commentService
  commentFindall(params: object = {}) {
    return commentService.findAll(params);
  },
  commentAdd(params: object = {}) {
    return commentService.commentadd(params);
  },
  commentPostFindone(id: number, params: object = {}) {
    return commentService.postFindone(id, params);
  },
  commentFindone(id: number) {
    return commentService.findone(id);
  },
  commentRemove(id: number) {
    return commentService.remove(id);
  },
  //eventService
  eventFindAll(params: object = {}) {
    return eventService.findAll(params);
  },
  eventProgress(params: object = {}) {
    return eventService.eventProgress(params);
  },
  eventEnd(params: object = {}) {
    return eventService.eventEnd(params);
  },
  eventFindone(id: number) {
    return eventService.findone(id);
  },
  //festivalService
  festivalFineAll(params: object = {}) {
    return festivalService.findAll(params);
  },
  festivalProgress(params: object = {}) {
    return festivalService.festivalProgress(params);
  },
  festivalStandby(params: object = {}) {
    return festivalService.festivalStandby(params);
  },
  festivalEnd(params: object = {}) {
    return festivalService.festivalEnd(params);
  },
  //reportService
  reportAdd(params: object = {}) {
    return reportService.reportAdd(params);
  },
  //inquiryService
  inquiryAdd(params: object = {}) {
    return inquiryService.inquiryAdd(params);
  },
  festivalOne(id: number) {
    return festivalService.findone(id);
  },
  // advertisementService
  advertisementFeedFindAll(params: object = {}) {
    return advertisementService.feedFindAll(params);
  },
  advertisementFindAll(params: object = {}) {
    return advertisementService.findAll(params);
  },
  // requestService
  requestAdd(params: object = {}) {
    return requestService.add(params);
  }
}