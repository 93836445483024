
//IonSkeletonText
import { Browser } from "@capacitor/browser";
import {
  IonSlides,
  IonSlide,
  toastController,
  IonPage,
  IonContent,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonImg,
  IonIcon,
  IonButton,
  IonButtons,
  // IonToolbar,
  // IonHeader,
  modalController,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../components/Empty.vue";
import { useStore } from "vuex";
import { Analytics } from "../../common/analytics";
import { useI18n } from "vue-i18n";
import Tutorial from "../../components/tutorial.vue";
import { VERSION } from "../../common/config";
import { getPlatforms } from "@ionic/vue";

/**
 * services
 */
import services from "../../services";

export default defineComponent({
  components: {
    IonSlides,
    IonSlide,
    IonContent,
    IonPage,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
    Empty,
    IonImg,
    IonIcon,
    IonButton,
    IonButtons,
    // IonToolbar,
    // IonHeader,
  },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup() {
    const { t } = useI18n();
    const screenName = "PAGE-HOME";
    const analytics = new Analytics();

    const router = useRouter();
    const store = useStore();

    // data
    const state = reactive({
      isSnowing: false,
      isScroll: false,
      isTutorial: false,
      window: {
        top: 0,
      },
      groupDistricts: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      groupCategory: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      banner: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      mission: {
        loading: true,
        allItems: [],
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      event: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      advertisement: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      contentLoading: true,
      version: {
        number: 0,
        code: "",
      },
    });

    const platforms = getPlatforms();
    if (platforms.includes("hybrid") && platforms.includes("ios")) {
      state.version.number = VERSION.ios.number;
      state.version.code = VERSION.ios.code;
    } else if (platforms.includes("hybrid") && platforms.includes("android")) {
      state.version.number = VERSION.android.number;
      state.version.code = VERSION.android.code;
    } else {
      state.version.number = VERSION.web.number;
      state.version.code = VERSION.web.code;
    }

    // 언어
    const lang = store.getters["getLanguage"];
    const tutorial = require.context(
      // "../../assets/img/tutorial/",
      "",
      true,
      /[A-Za-z0-9-_,\s]+\.png$/i
    );

    const tutorialResource: string[] = [];
    tutorial.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched.input?.split("/")[1];
        if (locale == lang) {
          tutorialResource.push(tutorial(key));
        }
      }
    });
    console.log(tutorialResource);
    // 튜토리얼 유무 체크
    const version = store.getters["getTutorial"];
    if (
      0 < tutorialResource.length &&
      version != `${state.version.number}_${state.version.code}`
    ) {
      // 튜토리얼 팝업
      console.log("튜토리얼 팝업");
      state.isTutorial = true;
    }

    const slideOptionOne = {
      slidesPerView: "auto",
      speed: 400,
      pagination: false,
    };
    const slideOptionTwo = {
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      speed: 400,
    };

    const openToast = async (message: any) => {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    };

    const onMissionRefresh = () => {
      const items = state.mission.allItems;
      if (items) {
        items.sort(() => {
          return Math.random() - Math.random();
        });
        state.mission.items = [];

        items.some((item) => {
          if (state.mission.items?.length < 4) {
            state.mission.items.push(item);
          } else {
            return false;
          }
        });
      }
    };

    const openPopup = async (
      popupName: string,
      items: any,
      totalItems: any,
      totalPages: any
    ) => {
      const modal: any = await modalController.create({
        component: Tutorial,
        backdropDismiss: false,
        cssClass: "popup_page",
        componentProps: {
          popupName: popupName,
          items: items,
          totalItems: totalItems,
          totalPages: totalPages,
        },
      });

      return modal.present();
    };

    const fetchPopup = async () => {
      const nowDate = new Date();
      const date = store.getters["getPopupDate"];
      let isPopup = false;
      if (date == null) {
        isPopup = true;
      } else {
        const popupDate = new Date(date);
        const elapsedMSec = nowDate.getTime() - popupDate.getTime();
        const elapsedHour = elapsedMSec / 1000 / 60 / 60;
        if (24 < elapsedHour) {
          isPopup = true;
        }
      }
      if (isPopup) {
        await services.popupFindAll().then(
          (response) => {
            const { data } = response;
            console.log(data);
            openPopup("popup", data.items, data.totalItems, data.totalPages);
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
          }
        );
      }
    };

    const fetchTutorial = async () => {
      const items: any[] = [];
      tutorialResource.forEach((src) => {
        items.push({
          cover: {
            name: "tutorial",
            url: "",
            path: src,
          },
        });
      });

      const modal: any = await modalController.create({
        component: Tutorial,
        backdropDismiss: false,
        cssClass: "popup_page",
        componentProps: {
          popupName: "tutorial",
          items: items,
          totalItems: tutorialResource.length,
          totalPages: 1,
        },
      });

      modal.onDidDismiss().then((response: any) => {
        const { data } = response;
        console.log(data.popupName);
        if (data.popupName == "tutorial") {
          fetchPopup();
        }
      });
      return modal.present();
    };

    const fetchWeather = async () => {
      await services.findWeather().then((response) => {
        const { data } = response;
        if (data.season == "winter" && data.weather == "snow") {
          state.isSnowing = true;
        } else {
          state.isSnowing = false;
        }
      });
    };

    const fetch = async (event: any = null) => {
      await services.groupDistrictsFineAll().then(
        (response) => {
          const { data } = response;

          if (event) {
            if (event.type == "ion-refresh") {
              state.groupDistricts.items = [];
            }
          }
          state.groupDistricts.items = data.items;
          state.groupDistricts.totalItems = data.totalItems;
          state.groupDistricts.totalPages = data.totalPages;
          state.groupDistricts.loading = false;
          console.log(state.groupDistricts);
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.groupDistricts.items = [];
            }
          }
          state.groupDistricts.loading = false;
        }
      );

      await services.groupCategoryFindAll().then(
        (response) => {
          const { data } = response;

          if (event) {
            if (event.type == "ion-refresh") {
              state.groupCategory.items = [];
            }
          }

          state.groupCategory.items = data.items;
          state.groupCategory.totalItems = data.totalItems;
          state.groupCategory.totalPages = data.totalPages;
          state.groupCategory.loading = false;
          console.log(state.groupCategory);
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.groupCategory.items = [];
            }
          }
          state.groupCategory.loading = false;
        }
      );

      await services.bannerContentFineAll().then(
        (response) => {
          const { data } = response;

          if (event) {
            if (event.type == "ion-refresh") {
              state.banner.items = [];
            }
          }

          state.banner.items = data.items;
          state.banner.totalItems = data.totalItems;
          state.banner.totalPages = data.totalPages;
          state.banner.loading = false;
          console.log(state.banner);
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.banner.items = [];
            }
          }
          state.banner.loading = false;
        }
      );

      await services.missionFeedFineAll().then(
        (response) => {
          const { data } = response;

          if (event) {
            if (event.type == "ion-refresh") {
              state.mission.items = [];
            }
          }

          data.items?.some((item: any) => {
            if (item.complete == 0) {
              if (item.content.reward[0].pictureGray) {
                if (item.content.reward[0].pictureGray.name) {
                  item.content.reward[0].picture =
                    item.content.reward[0].pictureGray;
                }
              }
            }
            if (item.completeStampCount < item.acquisitionStampCount) {
              item.acquisitionStampCount = item.completeStampCount;
            }
          });

          state.mission.allItems = data.items;
          state.mission.items = data.items;
          state.mission.totalItems = data.totalItems;
          state.mission.totalPages = data.totalPages;
          state.mission.loading = false;

          onMissionRefresh();
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.mission.items = [];
            }
          }
          state.mission.loading = false;
        }
      );

      await services
        .bannerGeneralFineAll({
          screen: "home",
        })
        .then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }

            data.items?.some((item: any) => {
              item.title = item.title.replace(/\n/g, "<br />");
              item.shortText = item.shortText.replace(/\n/g, "<br />");
            });

            state.event.items = data.items;
            state.event.totalItems = data.totalItems;
            state.event.totalPages = data.totalPages;
            state.event.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }
            state.event.loading = false;
          }
        );

      await services.advertisementFeedFindAll().then(
        (response) => {
          const { data } = response;

          if (event) {
            if (event.type == "ion-refresh") {
              state.advertisement.items = [];
            }
          }

          state.advertisement.items = data.items;
          state.advertisement.totalItems = data.totalItems;
          state.advertisement.totalPages = data.totalPages;
          state.advertisement.loading = false;
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.advertisement.items = [];
            }
          }
          state.advertisement.loading = false;
        }
      );

      const getTutorial = window.localStorage.getItem("isTutorial");
      if (!state.isTutorial && !event) {
        // 튜토리얼이 없는 경우
        console.log("fetchPopup");
        fetchPopup();
      } else if (state.isTutorial && !event) {
        // 튜토리얼 띄워주기
        if (!getTutorial) {
          fetchTutorial();
        } else {
          fetchPopup();
        }
      }

      setTimeout(() => {
        state.contentLoading = false;
        if (event) {
          event.target.complete();
        }
      }, 500);
    };

    const validURL = (str: string) => {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    };

    const onBannerLink = async (item: any) => {
      if (validURL(item.url)) {
        await Browser.open({ url: item.url });
      } else {
        openToast(`${t("cantOpenLink")}`);
      }
    };

    const onGroupLink = (id: number, title: string) => {
      // 페이지 타이틀 정보를 저장한다.
      store.commit("page", {
        title: title,
      });

      // 페이지 이동을 한다.
      router.push({
        name: "GroupId",
        params: { id: id },
      });
    };

    // TODO: 언어팩 사용해야하는지 확인
    const onMissionLink = (id: number) => {
      store.commit("page", "미션상세보기");
      router.push({
        name: "MissionId",
        params: { id: id },
      });
    };

    const onEventLink = async (item: any) => {
      if (validURL(item.url)) {
        await Browser.open({ url: item.url });
      }
      // else {
      //   openToast('링크를 열수 없습니다.');
      // }
    };

    const onOpenUrl = async (url: string) => {
      if (validURL(url)) {
        await Browser.open({ url: url });
      }
      // else {
      //   openToast('링크를 열수 없습니다.');
      // }
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.groupDistricts.loading = true;
      state.groupCategory.loading = true;
      state.banner.loading = true;
      state.mission.loading = true;
      state.event.loading = true;
      state.advertisement.loading = true;

      fetch(event);
    };

    // onMounted
    onMounted(() => {
      analytics.setScreenName(screenName);
      state.groupDistricts.items = [];
      state.groupCategory.items = [];
      state.banner.items = [];
      state.mission.items = [];
      state.event.items = [];
      state.advertisement.items = [];
      fetchWeather();
      fetch();
    });

    return {
      t,
      slideOptionOne,
      slideOptionTwo,
      state,
      onGroupLink,
      onMissionRefresh,
      onMissionLink,
      onRefresh,
      onEventLink,
      onBannerLink,
      onOpenUrl,
    };
  },
});
