import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { getPlatforms } from '@ionic/vue';

export class Analytics {

  constructor() {
    this.initFb();
  }

  async initFb() {
    console.log('initFb');
    const platforms = getPlatforms();
    if ( platforms.includes('hybrid') && platforms.includes('ios') ) {
      console.log('ios');
    } else if ( platforms.includes('hybrid') && platforms.includes('android') ) {
      console.log('android');
    } else if ( platforms.includes('mobileweb') && (platforms.includes('ios') || platforms.includes('android')) ) {
      console.log('web');
      /**
       * Platform: Web
       * Configure and initialize the firebase app.
       * @param options - firebase web app configuration options
       * */
      FirebaseAnalytics.initializeFirebase({
        apiKey: "AIzaSyBHVYmIns2iZT6wh4lOmFEMaGi-1eA0OKk",
        authDomain: "z1mw-a279c.firebaseapp.com",
        projectId: "z1mw-a279c",
        storageBucket: "z1mw-a279c.appspot.com",
        messagingSenderId: "639037087782",
        appId: "1:639037087782:web:5862a2bb566dabdf6386e7",
        measurementId: "G-T2631VB6YK",
      });
    }
  }

  setScreenName(screenName: string) {
    console.log('setScreenName');
    FirebaseAnalytics.setScreenName({
      screenName
    });
  }
}
