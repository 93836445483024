
import "@/assets/css/style.css";
import "highlight.js/styles/monokai.css";
import {
  IonApp,
  IonRouterOutlet,
  alertController,
  modalController,
} from "@ionic/vue";
import { defineComponent, onMounted } from "vue";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { getPlatforms } from "@ionic/vue";
import { AppLauncher } from "@capacitor/app-launcher";
import { App } from "@capacitor/app";
import services from "./services";
import { VERSION } from "./common/config";
import { StatusBar, Style } from "@capacitor/status-bar";
import AppDownloadgo from "./components/appDownloadgo.vue";
import { useStore } from "vuex";
import { AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    const platforms = getPlatforms();
    const store = useStore();
    const route = useRoute();
    let isAppInstall = false;
    let isAppUpdate = false;
    let os = "";
    console.log(platforms);

    // 언어셋팅
    let lang = store.getters["getLanguage"];
    if (!lang) {
      if (navigator.language != null) {
        lang = navigator.language;
      }
    }

    lang = lang.toLowerCase().substring(0, 2);
    const { locale } = useI18n();
    lang = "ko";
    locale.value = lang;

    // 언어 업데이트
    store.commit("language", {
      language: lang,
    });

    if (platforms.includes("hybrid") && platforms.includes("ios")) {
      // 하이브리드앱 이고 IOS 로 실행 되었는가?
      // iOS 버전 체크
      isAppUpdate = true;
      os = "ios";
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
      StatusBar.setStyle({ style: Style.Light });
      StatusBar.setBackgroundColor({ color: "#fff000" });
      AppTrackingTransparency.requestPermission();
    } else if (platforms.includes("hybrid") && platforms.includes("android")) {
      // 하이브리드앱 이고 Android 로 실행 되었는가?
      // Android 버전 체크
      isAppUpdate = true;
      os = "android";
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    } else if (
      platforms.includes("mobileweb") &&
      (platforms.includes("ios") || platforms.includes("android"))
    ) {
      // 모바일웹이고 IOS 또는 Android 로 실행이 되었는가
      // 앱 설치 팝업
      isAppInstall = true;
      if (platforms.includes("ios")) {
        os = "ios";
      } else {
        os = "android";
      }
    }

    const appInstallConfirm = async () => {
      const modal = await modalController.create({
        component: AppDownloadgo,
        backdropDismiss: false,
        cssClass: "appdownload_page",
        componentProps: {
          os: os,
        },
      });
      return modal.present();
    };

    const presentConfirm = async (
      message: string,
      confirmCallback: any = null,
      cancelCallback: any = null
    ) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (confirmCallback) {
                confirmCallback();
              }
            },
          },
          {
            text: "취소",
            handler: () => {
              if (cancelCallback) {
                cancelCallback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const fetch = async () => {
      const params = {
        platform: os,
        number: 0,
        code: "0.0.0",
      };

      if (os == "ios") {
        params.number = VERSION.ios.number;
        params.code = VERSION.ios.code;
      } else if (os == "android") {
        params.number = VERSION.android.number;
        params.code = VERSION.android.code;
      }

      await services.appFindone(os, params).then(
        (response) => {
          const { data } = response;
          console.log(data);
          if (data.status == "update") {
            presentConfirm(
              "새로운 앱 버전이 출시되었습니다",
              async () => {
                if (os == "ios") {
                  await AppLauncher.openUrl({
                    url: "itms-apps://itunes.apple.com/app/id1207835232",
                  });
                } else {
                  await AppLauncher.openUrl({
                    url: "market://details?id=kr.nextinnovation.stamppop",
                  });
                }
              },
              async () => {
                if (data.forcedUpdate === "true") {
                  App.exitApp();
                }
              }
            );
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
        }
      );
    };

    // onMounted
    onMounted(() => {
      os = "ios";
      if ((os == "ios" || os == "android") && isAppInstall) {
        // 팝업
        const nowDate = new Date();
        const date = store.getters["getDownloadDate"];
        let isPopup = false;
        if (date == null) {
          isPopup = true;
        } else {
          // 하루가 지났을때
          // 현재 시간
          /*
          const year = nowDate.getFullYear();
          const month = nowDate.getMonth()+1;
          const day = nowDate.getDate()
          const hours = nowDate.getHours();
          const minutes = nowDate.getMinutes();
          const seconds = nowDate.getSeconds();
          console.log(`현재 ${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
          */

          const popupDate = new Date(date);
          /*
          const popupYear = popupDate.getFullYear();
          const popupMonth = popupDate.getMonth()+1;
          const popupDay = popupDate.getDate();
          const popupHours = popupDate.getHours();
          const popupMinutes = popupDate.getMinutes();
          const popupSeconds = popupDate.getSeconds();
          console.log(`팝업 ${popupYear}-${popupMonth}-${popupDay} ${popupHours}:${popupMinutes}:${popupSeconds}`);
          */

          const elapsedMSec = nowDate.getTime() - popupDate.getTime();
          const elapsedHour = elapsedMSec / 1000 / 60 / 60;
          if (24 < elapsedHour) {
            isPopup = true;
          }
        }

        if (isPopup) {
          appInstallConfirm();
        }
      } else if (isAppUpdate) {
        fetch();
      }
    });
  },
});
