
import { IonPage, IonTabs, IonTabBar, IonTabButton } from '@ionic/vue';
import { useRouter } from 'vue-router';

export default {
  name: 'MainLayout',
  /**
   * XXX
   * IonTabBar 추가
   */
  components: {  IonPage, IonTabs, IonTabBar, IonTabButton },
  setup() {
    const router = useRouter();
    const onRadar = () => {
      router.push({
        name: "Radar"
      })
    }

    return {
      onRadar
    }
  }
}
