export const API_URL =
  process.env.VUE_APP_BASE_URL;

export const DOAMIN_URL =
  process.env.VUE_APP_DOMAIN_URL;

export const SERVICE_API_URL = "/api/v3/app";

export const KAKAO_JAVASCRIPT_KEY =
  process.env.NODE_ENV == "development"
    ? "5fd2fc77b9fbdae1bb2a1298f00bff58"
    : "7db8c1c9c9558e73ef8165b723e6c809";

export const KAKAO_REST_API_KEY =
  process.env.NODE_ENV == "development"
    ? "49561eb351137be8c509166de2da1c47"
    : "324de4d89c5b794173c673a80ee2a60b";

export const KAKAO_ADMIN_KEY =
  process.env.NODE_ENV == "development"
    ? "38c781fe3f7cc4e61201d4ecb46d970c"
    : "a73359f24fa5152700075f2f71a8bf2e";

export const KAKAO_AUTH_SCOPE =
  process.env.NODE_ENV == "development"
    ? "account_email, gender, age_range, birthday, talk_message"
    : "account_email, gender, age_range, birthday, talk_message, birthyear";

export const VERSION = {
  android: {
    packageName: 'kr.nextinnovation.stamppop',
    number: 142,
    code: '3.5.9'
  },
  ios: {
    packageName: 'com.stamppop.social',
    number: 75,
    code: '1.2.5'
  },
  web: {
    packageName: 'com.stamppop.app',
    number: 5,
    code: '1.0.3'
  }
};