const ID_TOKEN_KEY = "id_token";
const MARKERS_LOCATION = 'markers_location';

export const getAccessToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveAccessToken = (token: string) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyAccessToken = () => {
  console.log("destroyAccessToken");
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyRefreshToken = () => {
  console.log("destroyAccessToken");
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

// markers location
export const getMarkersLocation = () => {
  return window.localStorage.getItem(MARKERS_LOCATION);
};

export const saveMarkersLocation = (locations: any) => {
  window.localStorage.setItem(MARKERS_LOCATION, locations);
};

export const destroyMarkersLocation = () => {
  console.log("destroyMarkersLocation");
  window.localStorage.removeItem(MARKERS_LOCATION);
};

export default {
  getAccessToken,
  saveAccessToken,
  destroyAccessToken,
  destroyRefreshToken,
  getMarkersLocation,
  saveMarkersLocation,
  destroyMarkersLocation,
};
