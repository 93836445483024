import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/stamp", {
    params: params,
    headers: authHeader()
  });
};

const feedFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/stamp/feed", {
    params: params,
    headers: authHeader()
  });
};

const groupFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/stamp/group", {
    params: params,
    headers: authHeader()
  });
};

const missionFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/stamp/mission", {
    params: params,
    headers: authHeader()
  });
};

const userFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/stamp/user", {
    params: params,
    headers: authHeader()
  });
};

const findone = (id: number) => {
  return http.get(SERVICE_API_URL + `/stamp/${id}`, {
    headers: authHeader()
  });
};

const acquisitionFindone = (id: number) => {
  return http.get(SERVICE_API_URL + `/stamp/acquisition/${id}`, {
    headers: authHeader()
  });
};

const groupFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/stamp/group/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const missionFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/stamp/mission/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const userFindone = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/stamp/user/${id}`, {
    params: params,
    headers: authHeader()
  });
};

const acquisitionFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/stamp/acquisition", {
    params: params,
    headers: authHeader()
  });
};

const acquisitionAdd = (params: object) => {
  return http.post(SERVICE_API_URL + "//stamp/acquisition", params, {
    headers: authHeader()
  });
};

const reviewFindAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/stamp/review", {
    params: params,
    headers: authHeader()
  });
};

const reviewAdd = (params: object) => {
  return http.post(SERVICE_API_URL + "/stamp/review", params, {
    headers: authHeader()
  })
}

const reviewRemove = (id: number) => {
  return http.delete(SERVICE_API_URL + `/stamp/review/${id}`, {
    headers: authHeader()
  });
};

export default {
  findAll,
  feedFindAll,
  findone,
  groupFindAll,
  groupFindone,
  userFindAll,
  userFindone,
  missionFindAll,
  missionFindone,
  acquisitionFindAll,
  acquisitionAdd,
  acquisitionFindone,
  reviewFindAll,
  reviewAdd,
  reviewRemove
}